.footer_container {
    display: flex;
    flex-wrap: wrap;
    background: #EEE;
    justify-content: center;
    margin-top: 15px;
    padding: 15px 0;
}

.footer_box {
    min-width: 350px;
}


.social {
    display: grid;
    justify-content: space-evenly;
    margin-top: 20px;
}

.footer_logo {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin: 10px auto;
}

@media screen and (min-width: 700px) {
    .contact {
        display: grid;
        align-items: center;
    }
    
    .contact p {
        text-align: center;
    }

    .social {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
    }

    .footer_icon {
        display: grid;
        place-content: center;
    }
}