.ProductView__img-container {
    display: grid;
    place-items: center;
    overflow: hidden;
}

.ProductView__img {
    top: 0;
    width: 320px;
}

.ProductView__img-carousell {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ProductView__img-carousell-min {
    width: 100px;
}

.ProductView__info-title {
    height: 100px;
    color: #6f263d;
    font-weight: bold;
    font-size: 40px;
    padding-top: 20px;
}

.ProductView__info-subtitle {
    color: #6f263d;
    font-weight: bold;
    font-size: 30px;
    padding-top: 20px;
}

.ProductView__img-carousell-sub {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ProductView__img-carousell-use {
    width: 180px;
}

.ProductView__info-accordion {
    margin-top: 35px;
}


.accordion {
    box-shadow: none !important;
    width: calc(95% - 10px) !important;
    margin: auto !important;
}

.accordion-title {
    color: #75787b;
    font-size: 20px !important;
}

.accordion-desc {
    font-size: 18px !important;
    color: #555 !important;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
}

.img-certificate{
    width: 40%;
}

@media screen and (min-width: 768px) {
    .ProductView__img-container {
        display: grid;
        place-items: center;
        overflow: hidden;
    }

    .ProductView__img-carousell {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .ProductView__info-title {
        text-align: left;
        grid-column-start: 2;
    }

    .ProductView__img {
        width: 380px !important;
        position: relative;
        bottom: 80px;
        margin-bottom: 0;
        overflow: hidden;
    }

    .ProductView__img-carousell {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .ProductView__img-carousell-min {
        width: 100px;
    }

    .accordion {
        box-shadow: none !important;
        width: calc(100% - 10px) !important;
        margin-left: 20px !important;
    }
}