.cardProduct__image {
    transition: 500ms all;
}

.cardProduct__image:hover {
    opacity: 0.7;
    transform: scale(1.05)
}

@media (min-width: 840px) {
    .cardProduct__image {
        width: 280px !important;
    }
}