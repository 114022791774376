.destacadoContainer:hover {
    transition: all 0.7s linear;
}

.destacadoContainer_img {
    transform: scale(1);
    transition: all 0.4s ease-in-out
}

.destacadoContainer_img:hover {
    transform: scale(1.2);
}

@media (min-width: 800px) {
    .destacadoContainer {
        margin: 5px;
        height: 350px;
    }

    .destacadoContainer img {
        width: 200px !important;
    }    
}