.card__image {
    transition: 500ms all;
}

.card__image:hover {
    opacity: 0.7;
    transform: scale(1.05)
}

@media (min-width: 840px) {
    .card__image {
        width: 400px !important;
    }
}