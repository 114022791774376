.layout_header {
  font-family: 'Montserrat', sans-serif;
  font-size: 62.5%; 
  margin-bottom: 15px;
}

.layout_header-top {
    padding: 7px;
    height: 25px;
    background: #6f263d;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.layout_header-top-a {
    color: #FFF;
    font-size: 12px;
    text-decoration: none;
}

.layout_header-image {
    display: grid;
    place-items: center;
    margin-top: 20px;
}

.layout_header-link {
    display: none;
}

.layout_header-image img {
    width: 70vw;
    max-width: 300px;
}

.layout_header-contact {
    display: none;
}

@media (min-width: 800px) {
    .layout_header-image {
        display: flex;
        justify-content: left;
        padding-left: 70px;
        width: 400px;
    }

    .layout_header-link {
        display: grid;
        place-items: center;
        width: 150px;
    }

    .layout_header-contact {
        height: 30px;
        background: #3d0010;
        display: flex;
        justify-content: right;
        padding-right: 7%;
    }

    .layout_header-contact a {
        font-size: 13px;
        margin: auto 15px;
        color: #FFF;
        text-decoration: none;
    }
}